<template lang="pug">
  div.emailUpdate(v-loading="isLoading")
    .register__information(v-if='warnings')
      el-alert(
        title="Ошибка активации",
        type="error",
        show-icon,
        :closable="false",
      )
        div.errors_container(slot="default")
          div(v-for="(errors) in warnings")
            div(v-for="(error) in errors") {{ error }}
    .register__information(v-if="success")
      el-alert(
        title="Успешно",
        :description="successMessage"
        type="success",
        :closable="false",
        show-icon
      )
      .divider
      el-alert(
        title="",
        :description="`Через ${closeTimer} ${seconds} Вы будете перенаправлены на маркет`",
        type="warning",
        :closable="false",
      )
    .register__information(v-if="error")
      el-alert(
        title="",
        description="Вам",
        type="error",
        :closable="false",
      )
</template>

<script>
  import utilsMixin from '../components/mixins/utils';
  import {mapGetters} from 'vuex';
  import {EMAIL_CONFIRM} from '../store/actions/user';
  import {RESET_ERRORS} from '../store/actions/events';

  export default {
    mixins: [utilsMixin],
    data() {
      return {
        success: false,
        error: false,
        closeTimer: 3,
        interval: null,
        successMessage: '',
        types: {
          update: 'confirm-email-update',
          confirm : 'confirm-email',
        },
        messages: {
          update: 'Прежний E-mail успешно откреплен от Вашей записи',
          confirm: 'E-mail успешно подтвержден'
        }
      }
    },
    computed: {
      ...mapGetters([
        'loading',
        'loadingRequest',
      ]),
      isLoading() {
        return this.loading(EMAIL_CONFIRM) === 'loading';
      },
      requestData() {
        let req = this.loadingRequest(EMAIL_CONFIRM);
        return (req && req.response ? req.response : {});
      },
      warnings() {
        if (this.loading(EMAIL_CONFIRM) === 'error') {
          if(this.requestData.data){
            if(this.requestData.data.errors){
              return this.requestData.data.errors;
            } else if (this.requestData.data.message){
              return [[this.requestData.data.message]]; // TODO убрать это
            }
          }
          return false;
        }
        return false;
      },
      seconds(){
        return this.noun(this.closeTimer, 'секунду', 'секунды', 'секунд')
      }
    },
    mounted() {
      this.confirmEmail();
    },
    beforeDestroy(){
      this.$store.commit(RESET_ERRORS, { name: EMAIL_CONFIRM, data: null });
    },
    methods: {
      confirmEmail() {
        const type = this.types[this.$route.params.type];
        const token = this.$route.params.token;
        if(type){
          this.$store.dispatch(EMAIL_CONFIRM, {
            params: { type, token },
            token
          }).then(() => {
            this.$set(this, 'success', true);
            this.$set(this, 'successMessage', this.messages[this.$route.params.type]);
            this.interval = setInterval(() => {
              this.closeTimer -= 1;
              if(this.closeTimer === 0){
                this.$set(this, 'success', false);
                this.$router.push({ name: 'Login' });
                clearInterval(this.interval);
              }
            }, 1000);
          })
        } else {
          this.$router.push({ name: 'NotFound' });
        }
      },
    },
  }
</script>
<style lang="scss">
  .emailUpdate{
    .register__information{
      width: 410px;
      margin: 50px auto 0;
      display: block;
    }
  }
  .emailUpdate {
    font-size: 16px;
  }
</style>
